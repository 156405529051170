<template>
  <div class="take-photo-wrapper">
    <div class="content-layout-fixer">
      <div class="content">
        <div class="left">
          <div class="img-box">
            <img
              src="@/assets/logos/philip-morris-logo.png"
              class="logo"
              alt="philip-morris-logo"
            />
          </div>
        </div>
        <div class="right">
          <VueText sizeLevel="12" weightLevel="3"
            >Fotoğraf çekimi öncesinde dikkat edilmesi gerekenler</VueText
          >
          <ul class="info-list">
            <li class="info-list-item">
              Her rafın fotoğrafı çekilmeden önce üzerinde bulunan QR kod okutulmalıdır.
            </li>
            <li class="info-list-item">
              Fotoğraf çekimi öncesi dolapların kapağı açılmalıdır.
            </li>
            <li class="info-list-item">
              Fotoğraflar maksimum 1-1.5 metre mesafeden çekilmelidir.
            </li>
            <li class="info-list-item">Raf uygun mesafeden dik açı ile çekilmelidir.</li>
            <li class="info-list-item">Çekilen fotoğrafta tüm etiketler okunabilir olmalıdır.</li>
            <li class="info-list-item">
              Ürünleri ve/veya etiketleri engelleyen tüm objeler kaldırılmalıdır.
            </li>
            <li class="info-list-item">Etiketler doğru ürünün tam altında bulunmalıdır.</li>
            <li class="info-list-item">
              Herhangi bir sorun ile karşılaşırsanız menü üzerinden "Sık sorulan sorular" kısmını
              inceleyebilirsiniz
            </li>
          </ul>
          <VueText
            style="margin-top: 50px; margin-bottom: 20px; cursor: pointer;"
            sizeLevel="5"
            weightLevel="2"
            color="blue-30"
            @click="openTutorialVideo()"
            >Kullanım videosunu izlemek için tıklayınız
            <VueIcon
              @click="openTutorialVideo()"
              :width="constants.icons.chevronDown.width"
              :height="constants.icons.chevronDown.height"
              :iconName="constants.icons.chevronDown.name"
              style="margin-left: 10px;"
            />
          </VueText>

          <BrandVimeoPlayer
            v-if="isTutorialVideoVisible"
            class="video-wrapper"
            ref="vimeoPlayer"
            :data="tutorialVideo"
            :id="tutorialVideo.embedVideo"
          />
          <VueText
            style="margin-top: 30px; cursor: pointer;"
            sizeLevel="5"
            weightLevel="2"
            color="blue-30"
            @click="downloadGuide"
            >Detaylı kullanım kılavuzunu indirmek için tıklayınız.</VueText
          >

          <BrandButton
            shape="rounded"
            iconName="IconCameraBig"
            :iconSize="{
              width: 33,
              height: 26,
            }"
            iconColor="#fff"
            class="take-photo"
            @click="getLocationAndRedirect()"
          >
            <VueText sizeLevel="5" weightLevel="3"> Fotoğraf Çek</VueText>
          </BrandButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import StorageProps from '@/mixins/storageProps.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import BrandButton from '@/components/brand/BrandButton/BrandButton';
import LocationMixin from '@/utils/locationUtils.js';
import StaticContent from '@/services/Api/staticContents';
import BrandVimeoPlayer from '@/components/brand/BrandVimeoPlayer/BrandVimeoPlayer.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';

export default {
  name: 'ImageRecognition',
  components: { VueText, BrandButton, BrandVimeoPlayer, VueIcon },
  mixins: [StorageProps, LocationMixin],
  data() {
    return {
      isTutorialVideoVisible: false,
      tutorialVideo: {
        embedVideo: 'https://vimeo.com/759792291',
        title: 'tutorialVideo',
      },
    };
  },
  computed: {
    constants() {
      return {
        size: COMPONENT_CONSTANTS.COMPONENT_SIZES,
        icons: {
          waveHome: { ...COMPONENT_CONSTANTS.ICON_VARIABLES.waveHome, color: '#ffffff' },
          arrowLeft: { ...COMPONENT_CONSTANTS.ICON_VARIABLES.leftArrow, color: '#b4c2d3' },
          arrowRight: { ...COMPONENT_CONSTANTS.ICON_VARIABLES.rightArrow, color: '#b4c2d3' },
          truck: { ...COMPONENT_CONSTANTS.ICON_VARIABLES.truck, color: '#79838e' },
          wallet: { ...COMPONENT_CONSTANTS.ICON_VARIABLES.wallet, color: '#79838e' },
          priceListHome: { ...COMPONENT_CONSTANTS.ICON_VARIABLES.priceListHome, color: '#E5472D' },
          chevronDown: { ...COMPONENT_CONSTANTS.ICON_VARIABLES.chevronDown, color: '#0081f1' },
        },
      };
    },
  },
  methods: {
    downloadGuide() {
      StaticContent.getStaticContent('IRKullanimKilavuzu').then(res => {
        let {
          Data: { content },
        } = res.data;

        const baseUrl = process.env.VUE_APP_CDN_BASE_URL;
        window.open(`${baseUrl}${content}`, '_blank');
      });
    },
    openTutorialVideo() {
      this.isTutorialVideoVisible = !this.isTutorialVideoVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 40px 20px;
  position: relative;
  background-color: #fff;
  display: flex;
}
.content-header {
  display: flex;
  align-items: center;
}
.background {
  height: 110px;
  width: 100%;
  position: absolute;
  top: 0;
  background-size: cover;
}
.layout-default {
  /deep/ .layout-main-holder {
    top: -1px;
  }
}
.img-box {
  width: 150px;
  height: 150px;
  border-radius: 2px;
  border: solid 0.5px #b4c2d3;
  background-color: #ffffff;
  padding: 13px;
  flex-shrink: 0;
  margin-right: 30px;
  display: flex;
  align-items: center;
  .logo {
    max-width: 100%;
  }
}
.info-list {
  list-style: none;
  margin-top: 24px;
  &-item {
    font-size: 18px;
    line-height: 1.22;
    letter-spacing: normal;
    color: #79838e;
    padding-left: 20px;
    margin-bottom: 22px;
    position: relative;
    &::before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 8px;
      margin: 7px 10px 29px 0;
      background-image: linear-gradient(315deg, #d20051, #ffa300);
      position: absolute;
      left: 0;
    }
    a {
      color: #79838e;
    }
  }
  &-subitem {
    line-height: 1.22;
    letter-spacing: normal;
    color: #79838e;
    padding-left: 30px;
    margin-bottom: 18px;

    &:first-child {
      margin-top: 22px;
    }
    &::before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 8px;
      margin: 7px 10px 29px 0;
      background-image: linear-gradient(315deg, #d20051, #ffa300);
      position: absolute;
      left: 30px;
    }
  }
}
.take-photo {
  width: 180px !important;
  height: 54px !important;
  border-radius: 30px;
  margin-top: 50px;
}
</style>
